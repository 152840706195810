export enum ProfileStatus {
    NEW = 'NEW',
    DRAFT = 'DRAFT',
    REWORK = 'REWORK', //EDITING
    OPERATOR_APPROVAL = 'OPERATOR_APPROVAL',
    LAWYER_APPROVAL = 'LAWYER_APPROVAL',
    PODFT_APPROVAL = 'PODFT_APPROVAL',
    IDENTIFICATION = 'IDENTIFICATION',
    IDENTIFIED = 'IDENTIFIED',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED',
    ARCHIVED = 'ARCHIVED',
    // статус существует только на фронте
    ERROR = 'ERROR',
}

export const profileStatusTranslate = {
    [ProfileStatus.NEW]: 'Анкета создана',
    [ProfileStatus.DRAFT]: 'Анкета заполнена',
    [ProfileStatus.REWORK]: 'На доработке',
    [ProfileStatus.OPERATOR_APPROVAL]: 'На согласовании у операциониста',
    [ProfileStatus.LAWYER_APPROVAL]: 'На согласовании у юриста',
    [ProfileStatus.PODFT_APPROVAL]: 'На проверке ПОД/ФТ',
    [ProfileStatus.IDENTIFICATION]: 'Подлежит идентификации',
    [ProfileStatus.IDENTIFIED]: 'Прошел идентификацию',
    [ProfileStatus.APPROVED]: 'Зарегистрирован',
    [ProfileStatus.REJECTED]: 'Отклонена',
    [ProfileStatus.ARCHIVED]: 'В архиве',
}

export const approvalProfileStatuses = [
    ProfileStatus.REWORK,
    ProfileStatus.OPERATOR_APPROVAL,
    ProfileStatus.LAWYER_APPROVAL,
    ProfileStatus.PODFT_APPROVAL,
    ProfileStatus.IDENTIFICATION,
    ProfileStatus.REJECTED,
]

export const inProcessProfileStatuses = [
    ProfileStatus.REWORK,
    ProfileStatus.OPERATOR_APPROVAL,
    ProfileStatus.LAWYER_APPROVAL,
    ProfileStatus.PODFT_APPROVAL,
    ProfileStatus.IDENTIFICATION,
]

export const afterSendingStatuses = [
    ProfileStatus.REWORK,
    ProfileStatus.OPERATOR_APPROVAL,
    ProfileStatus.LAWYER_APPROVAL,
    ProfileStatus.PODFT_APPROVAL,
    ProfileStatus.IDENTIFICATION,
    ProfileStatus.REJECTED,
    ProfileStatus.ARCHIVED,
]

export const editableProfileStatuses = [
    ProfileStatus.NEW,
    ProfileStatus.DRAFT,
    ProfileStatus.REWORK,
]
export const negativeProfileStatuses = [ProfileStatus.REJECTED, ProfileStatus.ARCHIVED]
export const afterApprovalProfileStatuses = [
    ProfileStatus.IDENTIFICATION,
    ProfileStatus.IDENTIFIED,
    ProfileStatus.APPROVED,
]
