import { FilterField } from "../.."
import { TablePaginationParam } from "../request"

export type TTax = {
  id: string,
  created_at: string,
  updated_at: string,
  user_id: string,
  user_full_name: string,
  income_amount: number,
  tax_amount: number,
  tax_withholding: number,
  tax_period: TTaxPeriod
}

export type TTaxesSummary = {
  total_income_amount: number,
  total_writeoff_amount: number,
  net_income_amount: number,
  count_of_individuals: number,
  total_tax_amount: number,
  total_tax_withholding: number,
  to_withhold_amount: number
}

type TTaxPeriod = {
  id: string,
  created_at: string,
  updated_at: string,
  period_start: string,
  period_end: string,
  is_active: boolean
}

export interface ITaxWitholding {
  id: string,
  user_id: string,
  user_full_name: string,
  created_at: string,
  operation_code: TaxOperationCode,
  transaction_id: string,
  tax_amount: number,
  writeoff_amount: number
}

export type TTaxWitholdingSummary = {
  total_withholding_amount: number,
  total_writeoff_amount: number
}

export interface ITaxOperation {
  id: string,
  created_at: string,
  transaction_id: string,
  user_tax: IUserTax,
  ticker: string,
  emitter_id: string,
  operation_type: string,
  operation_code: string,
  amount: number,
  financial_result: number,
  income_amount: number,
  tax_amount: number,
  asset_id: number,
  count: number,
  meta: string
}

export type TTaxOperationsSummary = {
  total_amount: number,
  total_income_amount: number,
  total_tax_withholding: number
}

export interface IUserTax {
  id: string,
  created_at: string,
  updated_at: string,
  user_id: string,
  user_full_name: string
  income_amount: number,
  tax_amount: number,
  tax_withholding: number,
  tax_period: TTaxPeriod,
}

export enum TaxOperationCode {
  OP_EM_001 = 'OP-EM-001',
  OP_PS_001 = 'OP-PS-001',
  OP_PS_002 = 'OP-PS-002',
  OP_OTH_001 = 'OP-OTH-001',
  OP_OTH_002 = 'OP-OTH-002',
  OP_OTH_003 = 'OP-OTH-003',
  OP_OTH_011 = 'OP-OTH-011',
  OP_EX_001 = 'OP-EX-001',
  OP_CP_001 = 'OP-CP-001',
  OP_BO_001 = 'OP-BO-001',
  OP_BO_002 = 'OP-BO-002',
  OP_OTH_021 = 'OP-OTH-021',
  OP_OTH_022 = 'OP-OTH-022',
  OP_OTH_031 = 'OP-OTH-031',
  OP_OTH_032 = 'OP-OTH-032'
}

export const operationCodeTranslate: Record<TaxOperationCode, string> = {
  [TaxOperationCode.OP_EM_001]: 'Выпуск ЦФА',
  [TaxOperationCode.OP_PS_001]: 'Покупка ЦФА',
  [TaxOperationCode.OP_PS_002]: 'Продажа ЦФА',
  [TaxOperationCode.OP_OTH_001]: 'Направление Оферты',
  [TaxOperationCode.OP_OTH_002]: 'Акцепт Оферты',
  [TaxOperationCode.OP_OTH_003]: 'Передача ЦФА по Оферте',
  [TaxOperationCode.OP_OTH_011]: 'Переход прав на ЦФА',
  [TaxOperationCode.OP_EX_001]: 'Погашение ЦФА',
  [TaxOperationCode.OP_CP_001]: 'Выплата КД',
  [TaxOperationCode.OP_BO_001]: 'Блокирование ЦФА',
  [TaxOperationCode.OP_BO_002]: 'Разблокирование ЦФА',
  [TaxOperationCode.OP_OTH_021]: 'Наложение обременения на ЦФА',
  [TaxOperationCode.OP_OTH_022]: 'Снятие обременения с ЦФА',
  [TaxOperationCode.OP_OTH_031]: 'Вывод денежных средств',
  [TaxOperationCode.OP_OTH_032]: 'Прочие списания'
}

export type GetTaxWithholdingParams = TablePaginationParam & {
  user_id?: string,
  'user_tax.user_id'?: string,
  created_at?: FilterField<string>[] | string,
  lod?: 'summary',
  tax_amount?: FilterField<string>[] | number,
}

export type GetTaxesParams = TablePaginationParam & {
  lod?: 'summary',
  created_at?: FilterField<string>[] | string,
  user_id?: string,
  'tax_period.is_active'?: boolean
  income_amount?: FilterField<string>[] | number,
  tax_amount?: FilterField<string>[] | number,
  tax_withholding?: FilterField<string>[] | number,
  operation_type?: 'DEBIT' | 'CREDIT'
}

export type GetTaxOperationsParams = TablePaginationParam & {
  'user_tax.user_id'?: string,
  created_at?: FilterField<string>[] | string,
  operation_code?: TaxOperationCode[]
  amount?: FilterField<string>[] | number,
  tax_withholding?: FilterField<string>[] | number,
  operation_type?: 'DEBIT' | 'CREDIT'
}

export type PostTaxWithdrawalByOperatorParams = {
  user_uuid: string,
  withdrawal_amount: number,
  withdrawal_type: string,
  withdrawal_base?: string,
  withdrawal_base_document_uuid?: string,
  with_holdings_query?: GetTaxWithholdingParams | string
}