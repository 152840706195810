import { UploadFile } from 'antd/lib/upload/interface'
import { Moment } from 'moment'

import { XOR } from '../../utils'

import { IBankAccountsUser } from '../money'
import { BankAccountDetailsModel } from '../nominalAccount'
import { ProfileStatus } from '../profile'

import { BeneficialOwner } from './beneficialOwner'

export enum DocumentType {
    passport = 'passport',
    other = 'other',
}

export enum PublicStatusType {
    notPublic = 'notPublic',
    public = 'public',
    related = 'related',
}

export enum CitizenshipType {
    RU = 'RU',
    KZ = 'KZ',
}

export enum HasBeneficiary {
    YES = 'yes',
    NO = 'no',
}

export enum MemberStatusType {
    MEMBER = 'Член совета директоров',
    SHARER = 'Акционер (участник), владеющий 5% акций и более',
}

export enum TypeUserTitle {
    LEGAL = 'Юридическое лицо',
    PERSON = 'Физическое лицо',
    BUSINESSMAN = 'Индивидуальный предприниматель',
}

export enum TypeUserType {
    LEGAL = 'LEGAL',
    PERSON = 'PERSON',
    BUSINESSMAN = 'BUSINESSMAN',
}

export type JobModel = {
    country?: string
    title?: string
    official_address?: string
    position_title?: string
}
export type PublicOfficialModel = {
    am_i: boolean
    is_relative?: boolean
    relation?: string
    file_uuid?: string
    job?: JobModel
}

export type AttachmentModel = {
    title: string
    file_uuids: string[]
}

export type BasicPersonModel = {
    first_name: string
    last_name: string
    second_name?: string
    birthdate: number
    place_birth: string
    citizenship: CitizenshipType
}
export type PassportModel = {
    series: string
    number: string
    issue_date: number
    issued_by: string
    issuer_department_code: string
    file_uuid?: string
}
export type PersonModel = BasicPersonModel & {
    registration_address: string
    location_address: string
    post_address: string
    inn: string
    inn_file_uuid?: string
    snils: string
    snils_file_uuid?: string
    identity_document: DocumentType
    identity_document_file_uuid?: string
}
export type MigrationCardModel = {
    number: string
    start_date: number
    expiration_date: number
    file_uuid?: string
}

export type PermissionStayRFModel = {
    name: string
    series: string
    number: string
    start_date: number
    expiration_date: number
    file_uuid?: string
}
export type LicActivityModel = {
    type_activity: string
    lic_number: string
    date_of_issue: number
    issue_by: string
    validity?: number
    file_uuid?: string
}

export type BankDetailsModel = {
    account: string
    bank_title: string
    inn: string
    kpp: string
    bik: string
    corr_account: string
}

export type BusinessReputationType = {
    business_reputation: string
    file_uuids: string[]
}
export type UIBusinessReputation = {
    business_reputation: string
    file_uuids: UploadFile[]
}

export interface IBasicFinanceInformation {
    bankruptcy_decisions: boolean
    bankruptcy_proceedings: boolean
    debt_to_pay: boolean
    facts_of_non_fulfillment: boolean
    liquidation_procedure: boolean
    file_uuids?: string[]
}

export type NodeAndExchangeOperator = {
    is_node: boolean
    is_exchange_operator: boolean
    reason_description: string
    reason_link_uuid: string
}

export interface ICommonDataAnketa {
    id?: string
    created_at?: number
    updated_at?: number
    deleted_at?: number
}

type UserBankAccountProfileDetails = Omit<IBankAccountsUser, 'user_uuid' | 'account_details'> & {
    bank_account_details: BankAccountDetailsModel
}

export interface IFinanceAndOperation {
    planned_operations: string
    sources_funds: string
    sources_funds_file_uuids: string[] | undefined
    business_relations_type: RelationshipOfCompanyType | undefined
    financial_activity_purpose: PurposesOfActivityType[]
}

export interface ICommonDataProfile extends ICommonDataAnketa, IFinanceAndOperation {
    user_uuid?: string
    nominal_account_uuid?: string
    profile_status?: ProfileStatus
    node_and_exchange_operator?: NodeAndExchangeOperator
    user_bank_account_details?: UserBankAccountProfileDetails
    okato?: string
    report_okato?: string
}

export type UserAccountProfileDetails = Omit<IBankAccountsUser, 'user_uuid' | 'account_details'> & {
    account_details: BankAccountDetailsModel
}
export type CommonChangeProfileType = {
    linked_beneficiaries?: BeneficialOwner[]
    user_account_details: UserAccountProfileDetails[]
}

export type UIBankAccountDetailsType = {
    account_number: string
    bank: string
    bank_bic: string
    bank_inn: string
    bank_kpp: string
    correspondent_account: string
}

export type UIPermissionType = {
    HasMigrationPermit: boolean
    MigrationPermitArrivalDate?: Moment
    MigrationPermitDepartureDate?: Moment
    MigrationPermitDocuments?: UploadFile[]
    MigrationPermitName?: string
    MigrationPermitNumber?: string
    MigrationPermitSeries?: string
}

export type UIMigrationCardType = {
    HasMigrationCard: boolean
    MigrationCardArrivalDate?: Moment
    MigrationCardDepartureDate?: Moment
    MigrationCardDocuments?: UploadFile[]
    MigrationCardNumber?: string
}

export type UIPublicPersonForm = {
    status: PublicStatusType
    related_position?: string
    public_country?: string
    public_organisation?: string
    public_registration?: string
    public_position?: string
}

export type UILicActivityType = {
    type_activity: string
    lic_number: string
    date_of_issue: Moment
    issue_by: string
    validity?: Moment
    file_uuid?: UploadFile[]
}
export type UILicActivitiesType = {
    lic_activities?: UILicActivityType[]
}

export type UIPassportType = XOR<
    {
        documentType: DocumentType.passport
        PassportSeries: string
        PassportNumber: string
        issueDate: Moment
        PassportIssuer: string
        PassportCode: string
        PassportPages?: UploadFile[]
    },
    {
        documentType: Exclude<DocumentType, 'passport'>
    }
>

type BooleanAnswer = 'Верно' | 'Не верно'
export type UIFinanceInfo = {
    isBankrotInProgress: BooleanAnswer
    isBankrot: BooleanAnswer
    isLiquidationInProgress: BooleanAnswer
    isDebtGov: BooleanAnswer
    isNoMoney: BooleanAnswer
    finance_doc?: UploadFile[] | undefined
}

export type UIPlannedOperations = {
    planned_operations: string
    sources_funds: string
    sources_funds_file_uuids: UploadFile[] | undefined
    business_relations_type: RelationshipOfCompanyType | undefined
    financial_activity_purpose: PurposesOfActivityType[]
}

export type NodeAndExchangeOperatorUid = NodeAndExchangeOperator & { user_uuid: string }

export const enum RelationshipOfCompanyType {
    LONG = 'LONG',
    SHORT = 'SHORT',
}

export const RelationshipOfCompanyTranslate = {
    [RelationshipOfCompanyType.LONG]: 'Долгосрочный',
    [RelationshipOfCompanyType.SHORT]: 'Краткосрочный',
}

export const enum PurposesOfActivityType {
    INCREASING_REVENUE = 'INCREASING_REVENUE',
    INCREASING_ASSETS_VALUE = 'INCREASING_ASSETS_VALUE',
    LEADING_POSITIONS = 'LEADING_POSITIONS',
    COMMUNITY_PROJECTS = 'COMMUNITY_PROJECTS',
    JOB_CREATION = 'JOB_CREATION',
    OTHER = 'OTHER',
}

export const PurposesOfActivityTranslate = {
    [PurposesOfActivityType.INCREASING_REVENUE]: 'Получение  максимальной прибыли',
    [PurposesOfActivityType.LEADING_POSITIONS]: 'Завоевание лидирующих позиций в отрасли',
    [PurposesOfActivityType.INCREASING_ASSETS_VALUE]: 'Увеличение стоимости активов',
    [PurposesOfActivityType.COMMUNITY_PROJECTS]: 'Реализация общественных проектов',
    [PurposesOfActivityType.JOB_CREATION]: 'Создание дополнительных рабочих мест',
    [PurposesOfActivityType.OTHER]: 'Иное',
}

export type PatchProfileType = {
    agent_uuid?: string
    agent_uuids?: string[]
    nominal_account_uuid?: string
    report_okato?: string
}

// type RiskLevel = ['Низкий', 'Высокий']

export type RiskLevelProfile = {
    verification_datetime: string
    risk_level: string
    comment: string
}

export type PatchPrivateProfileType = {
    risk_levels: RiskLevelProfile[]
}

export type PrivateInformationLegalEntityResponse = {
    operator_uuid: string
    is_strategic_society: boolean
    is_nko: boolean
    user_identification_date: number
    user_termination_date: number
    risk_levels: RiskLevelProfile[]
}
