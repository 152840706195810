export enum EFileType {
  PDF = 'pdf',
  XML = 'xml'
}

export enum ESignerType {
  operator = '1',
  operator_representative = '2'
}

export type ReportTaxSettlementsTypeDirect = {
  file_type: EFileType
  program_version: string
  format_version: string
  knd_code: string,
  tax_authority_code: string
  report_year: string,
  correction_number: string,
  ois_operator: {
    phone?: string,
    ois_organisation: {
      name: string,
      tin: string,
      kpp: string
    }
  },
  signer: {
    type: ESignerType,
    full_name: {
      last_name: string,
      first_name: string,
      middle_name?: string
    },
    delegate: {
      doc_name: string
    }
  }
}